import * as React from 'react'
import { AiPopoverVm } from '../popover.types'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { store } from 'store/index'
import { AsyncTaskOutput } from 'store/modules/async-tasks'

export class AiSkillDescriptionVm implements AiPopoverVm {
  constructor(readonly source: string) {}

  readonly generateButtonText = 'Generate description'
  readonly onErrorMessage = 'Sorry there was a problem, please try again.'
  readonly onSuccessMessage = 'Description generated!'
  readonly generatingMessage =
    'This might take a few seconds. You can keep editing while we generate.'

  async onSubmit(inputs: Record<string, unknown>) {
    if (!inputs.skillName || typeof inputs.skillName !== 'string') {
      errorToast('Please provide a skill name')
      return
    }

    const activeTask = await store.asyncTasks.create(
      {
        type: 'open_ai',
        step: 'skill-description-generation',
        attributes: {
          skillName: inputs.skillName,
        },
      },
      { source: this.source }
    )

    if (activeTask.success && activeTask?.data?.asyncTasks) {
      const taskId = Object.keys(activeTask.data.asyncTasks)[0]
      return taskId
    }
  }

  content(output: AsyncTaskOutput) {
    return (
      <>
        <p className="text-gray-600 text-xs">Preview</p>
        <p>{output.skillDescription?.output?.extracted}</p>
      </>
    )
  }

  readonly formContent = null
}
