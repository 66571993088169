import { AiActionsSuggestionVm } from './ai-actions-suggestion-vm'
import { AiFocusSkillActionsVm } from './ai-focus-skill-actions-vm'
import { AiPositionDescriptionVm } from './ai-position-description-vm'
import { AiSkillContentVm } from './ai-skill-content-vm'
import { AiSkillDescriptionVm } from './ai-skill-description-vm'
import { AiSkillExampleVm } from './ai-skill-example-vm'
import { AiSkillLevelDescriptionVm } from './ai-skill-level-description-vm'
import { AiStoryOutlineVm } from './ai-story-outline-vm'
import { AiStorySummaryVm } from './ai-story-summary-vm'
import { AiWinsSuggestionVm } from './ai-wins-suggestion-vm'

export const AiVmMap = {
  skillDescription: AiSkillDescriptionVm,
  positionDescription: AiPositionDescriptionVm,
  skillExample: AiSkillExampleVm,
  skillLevelDescription: AiSkillLevelDescriptionVm,
  skillContent: AiSkillContentVm,
  actionsSuggestion: AiActionsSuggestionVm,
  winsSuggestion: AiWinsSuggestionVm,
  storySummary: AiStorySummaryVm,
  storyOutline: AiStoryOutlineVm,
  focusSkillActions: AiFocusSkillActionsVm,
}

export type AiVmMapKey = keyof typeof AiVmMap
