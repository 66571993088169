import * as React from 'react'
import { AiPopoverVm } from '../popover.types'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { store } from 'store/index'
import { AsyncTaskOutput } from 'store/modules/async-tasks'

export class AiWinsSuggestionVm implements AiPopoverVm {
  constructor(readonly source: string) {}

  readonly generateButtonText = 'Generate win!'
  readonly onErrorMessage = 'Sorry there was a problem, please try again.'
  readonly onSuccessMessage = 'Done'
  readonly generatingMessage = 'Holding up a mirror'

  async onSubmit(inputs: Record<string, unknown>) {
    if (!inputs.content || typeof inputs.content !== 'string') {
      errorToast('Please provide some content')
      return
    }

    const activeTask = await store.asyncTasks.create(
      {
        type: 'open_ai',
        step: 'wins-suggestion',
        attributes: {
          content: inputs.content,
        },
      },
      { source: this.source }
    )

    if (activeTask.success && activeTask?.data?.asyncTasks) {
      const taskId = Object.keys(activeTask.data.asyncTasks)[0]
      return taskId
    }
  }

  content(output: AsyncTaskOutput) {
    const r = output.winsSuggestion?.output?.extracted.at(0)

    return (
      <>
        <p className="text-gray-600 font-bold text-xs">Wins</p>
        {r?.items.map((a) => (
          <p key={Math.random()}>{a.text}</p>
        ))}
      </>
    )
  }

  readonly formContent = null
}
