import * as React from 'react'
import { AiPopoverVm } from '../popover.types'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { store } from 'store/index'
import { AsyncTaskOutput } from 'store/modules/async-tasks'
import { AiSkillContentForm } from '../forms/ai-skill-content-form'

export class AiSkillContentVm implements AiPopoverVm {
  constructor(readonly source: string) {}

  readonly generateButtonText = 'Generate all levels'
  readonly onErrorMessage = 'Sorry there was a problem, please try again.'
  readonly onSuccessMessage = 'Content generated!'
  readonly generatingMessage = 'This may take a minute or two.'

  async onSubmit(inputs: Record<string, unknown>) {
    if (!inputs.skillName || typeof inputs.skillName !== 'string') {
      errorToast('Please provide a skill name')
      return
    }

    const discipline =
      typeof inputs.discipline === 'string' ? inputs.discipline : ''

    const numberLevels =
      typeof inputs.numberLevels === 'number' ? inputs.numberLevels : 3

    const keywords = Array.isArray(inputs.keywords) ? inputs.keywords : []

    const activeTask = await store.asyncTasks.create(
      {
        type: 'open_ai',
        step: 'skill-content',
        attributes: {
          skillName: inputs.skillName,
          discipline: discipline,
          levelCount: numberLevels,
          keywords: keywords,
        },
      },
      { source: this.source }
    )

    if (activeTask.success && activeTask?.data?.asyncTasks) {
      const taskId = Object.keys(activeTask.data.asyncTasks)[0]
      return taskId
    }
  }

  // TODO: skill content will be previewed in future, but currently is applied once complete
  content(_output: AsyncTaskOutput) {
    return <>Not implemented</>
  }

  readonly formContent = AiSkillContentForm
}
