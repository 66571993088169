import * as React from 'react'
import { AiPopoverVm } from '../popover.types'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { store } from 'store/index'
import { AsyncTaskOutput } from 'store/modules/async-tasks'

export class AiStorySummaryVm implements AiPopoverVm {
  constructor(readonly source: string) {}

  readonly generateButtonText = 'Generate a summary'
  readonly onErrorMessage = 'Sorry there was a problem, please try again.'
  readonly onSuccessMessage = 'Done'
  readonly generatingMessage = 'Putting pen to paper'

  async onSubmit(inputs: Record<string, unknown>) {
    if (!inputs.updates || typeof inputs.updates !== 'object') {
      errorToast('Please provide some updates')
      return
    }

    const updates = Array.isArray(inputs.updates) ? inputs.updates : []

    const activeTask = await store.asyncTasks.create(
      {
        type: 'open_ai',
        step: 'story-summary',
        attributes: {
          updates,
        },
      },
      { source: this.source }
    )

    if (activeTask.success && activeTask?.data?.asyncTasks) {
      const taskId = Object.keys(activeTask.data.asyncTasks)[0]
      return taskId
    }
  }

  content(_output: AsyncTaskOutput) {
    return <>Not implemented</>
  }

  readonly formContent = null
}
