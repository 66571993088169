import {
  Banner,
  Button,
  GlobalConfirmationDialog,
  Input,
  InputGroup,
  useConfirmationDialogContext,
} from 'src/design-system'
import { Option, SelectInput } from 'components/atoms/select-input'
import { Plus, Trash } from '@phosphor-icons/react'
import * as React from 'react'
import { AiSkillContentFormVm } from './ai-skill-content-form-vm'
import { AiFormProps } from './forms.types'
import { modifyAtIndex, removeIndex } from 'app/packs/src/utils/array-helpers'
import { ConfirmationDialogProps } from 'app/packs/src/design-system/components/molecules/ConfirmationDialog'

type Keyword = {
  id: number
  value: string
}

export const AiSkillContentForm = (props: AiFormProps) => {
  const { inputs, startGeneration } = props

  const { openConfirmationDialog } = useConfirmationDialogContext()

  const vm = new AiSkillContentFormVm(inputs.teamType as string)

  const [discipline, setDiscipline] = React.useState(
    vm.defaultDisciplineOption?.value
  )
  const [numberLevels, setNumberLevels] = React.useState(
    vm.defaultLevelOption?.value
  )
  const [keywords, setKeywords] = React.useState<Keyword[]>([
    { id: 1, value: '' },
  ])

  const performGeneration = () => {
    startGeneration({
      discipline,
      numberLevels,
      keywords: keywords.map((k) => k.value),
    })
  }

  const generate = () => {
    if (inputs.showWarning) {
      openConfirmationDialog('overwrite-skill-with-ai-content', {
        title: 'Overwrite existing content?',
        body: 'Generating all the levels will permanently overwrite the existing content.',
        confirmLabel: 'Generate all levels',
        onConfirm: performGeneration,
      } satisfies ConfirmationDialogProps)
    } else {
      performGeneration()
    }
  }

  return (
    <div className="text-gray-900">
      <GlobalConfirmationDialog id={`overwrite-skill-with-ai-content`} />
      {inputs.showWarning && (
        <Banner variant="warning" className="mb-3 rounded">
          This will overwrite any existing content.
        </Banner>
      )}
      <InputGroup.Root className="mb-5">
        <InputGroup.Label>Discipline</InputGroup.Label>
        <SelectInput
          name="discipline"
          options={vm.disciplineOptions}
          defaultValue={vm.defaultDisciplineOption}
          onChange={(option) =>
            setDiscipline(String((option as Option)?.value) || '')
          }
        />
      </InputGroup.Root>

      <InputGroup.Root className="mb-5">
        <InputGroup.Label>Keywords or phrases</InputGroup.Label>
        <ul className="pl-4">
          {keywords.map((keyword) => (
            <li key={keyword.id} className="mb-2">
              <>
                <Input
                  naked
                  placeholder="E.g. Attention to detail, optimistic"
                  className="py-2 px-1 w-[90%]"
                  value={keyword.value}
                  onChange={(e) => {
                    const index = keywords.findIndex((k) => k.id === keyword.id)
                    const newKeywords = modifyAtIndex(keywords, index, {
                      id: keywords[index].id,
                      value: e.target.value,
                    })
                    setKeywords(newKeywords)
                  }}
                />
                {keywords.length > 1 && (
                  <Trash
                    className="text-gray-600 ml-2 mt-3.5 float-right cursor-pointer w-4"
                    onClick={() => {
                      const index = keywords.findIndex(
                        (k) => k.id === keyword.id
                      )
                      setKeywords(removeIndex(keywords, index))
                    }}
                  />
                )}
              </>
            </li>
          ))}
        </ul>
        <Button
          type="button"
          variant="outline"
          colourVariant="paper"
          onClick={() => {
            const maxId = Math.max(...keywords.map((k) => k.id))
            setKeywords([...keywords, { id: maxId + 1, value: '' }])
          }}
        >
          <Plus className="text-green-600" /> Add another
        </Button>
      </InputGroup.Root>

      <InputGroup.Root className="mb-5">
        <InputGroup.Label>Number of levels *</InputGroup.Label>
        <SelectInput
          className="mb-2"
          name="numberLevels"
          options={vm.levelOptions}
          defaultValue={vm.defaultLevelOption}
          onChange={(option) =>
            setNumberLevels(Number((option as Option)?.value))
          }
        />
        <p className="text-gray-600 text-sm">
          We recommend 5 levels, regardless of how many positions are in a
          Framework
        </p>
      </InputGroup.Root>

      <Button onClick={generate} fullWidth={true}>
        Generate levels
      </Button>
    </div>
  )
}
