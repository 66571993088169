import * as React from 'react'
import { AiPopoverVm } from '../popover.types'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { store } from 'store/index'
import { AsyncTaskOutput } from 'store/modules/async-tasks'

export class AiSkillExampleVm implements AiPopoverVm {
  constructor(readonly source: string) {}

  readonly generateButtonText = 'Generate example'
  readonly onErrorMessage = 'Sorry there was a problem, please try again.'
  readonly onSuccessMessage = 'Example generated!'
  readonly generatingMessage =
    'This might take a few seconds. You can keep editing while we generate.'

  async onSubmit(inputs: Record<string, unknown>) {
    if (
      !inputs.skillName ||
      typeof inputs.skillName !== 'string' ||
      !inputs.skillDescription ||
      typeof inputs.skillDescription !== 'string' ||
      !inputs.level ||
      typeof inputs.level !== 'number' ||
      !inputs.count ||
      typeof inputs.count !== 'number' ||
      !inputs.existingExamples ||
      typeof inputs.existingExamples !== 'object' ||
      !inputs.skillLevelDescription ||
      typeof inputs.skillLevelDescription !== 'string'
    ) {
      errorToast('Please ensure there is a skill name and description.')
      return
    }

    const activeTask = await store.asyncTasks.create(
      {
        type: 'open_ai',
        step: 'skill-example-generation',
        attributes: {
          count: inputs.count,
          skillName: inputs.skillName,
          skillDescription: inputs.skillDescription,
          level: inputs.level,
          skillLevelDescription: inputs.skillLevelDescription,
          existingExamples: inputs.existingExamples,
        },
      },
      { source: this.source },
      { bypassCache: true }
    )

    if (activeTask.success && activeTask?.data?.asyncTasks) {
      const taskId = Object.keys(activeTask.data.asyncTasks)[0]
      return taskId
    }
  }

  content(output: AsyncTaskOutput) {
    return (
      <>
        <p className="text-gray-600 text-xs">Preview</p>
        {output.skillExamples?.output.extracted.map((example) => (
          <p key={example.skillExampleName}>
            <strong>{example.skillExampleName}</strong>:{' '}
            {example.skillExampleDescription}
          </p>
        ))}
      </>
    )
  }

  readonly formContent = null
}
