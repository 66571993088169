export class AiSkillContentFormVm {
  constructor(readonly teamType: string) {}

  DEFAULT_NUMBER_OF_LEVELS = 5

  get disciplineMappings() {
    return [
      {
        label: 'Human',
        mappings: ['customer_success', 'legal', 'marketing', 'operations'],
      },
      { label: 'Leadership', mappings: [''] },
      { label: 'Design', mappings: ['design'] },
      { label: 'Engineering', mappings: ['engineering'] },
      { label: 'Finance', mappings: ['finance'] },
      { label: 'People & HR', mappings: ['people'] },
      { label: 'Product', mappings: ['product'] },
      { label: 'Sales', mappings: ['sales'] },
      { label: 'User Research', mappings: ['research'] },
      { label: 'Other', mappings: ['operations other'] },
    ]
  }

  get levels() {
    return Array.from({ length: 7 }, (_, i) => i + 1)
  }

  get disciplineOptions() {
    return this.disciplineMappings.map((discipline) => ({
      label: discipline.label,
      value: discipline.label.toLowerCase(),
    }))
  }

  get levelOptions() {
    return this.levels.map((level) => ({
      label: `${level}`,
      value: level,
    }))
  }

  get defaultLevelOption() {
    return this.levelOptions.find(
      (option) => option.value === this.DEFAULT_NUMBER_OF_LEVELS
    )
  }

  get defaultDisciplineOption() {
    const preSelect = this.disciplineOptions.find(
      (option) => option.value === this.teamType
    )

    if (!preSelect) return undefined

    return {
      label: preSelect?.label,
      value: preSelect?.label?.toLowerCase(),
    }
  }
}
